import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="April 2021" subnav="release-notes">
      <div id="April2021" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Spring is here, or so they say. But, it's clear that Uniform has
          sprung some massive improvements all across the product.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.7.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.7.0"
            />

            <div className={styles.changesList}>
              <Added>
                <code>buttonLabel</code> and <code>buttonIcon</code> props to{' '}
                <Link href="/components/action-list/design">action list</Link>{' '}
                to allow either custom text or an icon.
              </Added>
              <Added>
                A ton of new <Link href="/components/icons/design">icons</Link>{' '}
                across the effects, interactions, logos, technology and UI
                control categories. 
              </Added>
              <Added>
                <code>isTight</code> prop to{' '}
                <Link href="/components/notifications/notice/code">notice</Link>, 
                which is <code>true</code> by default.
              </Added>
              <Improved>
                <Link href="/components/forms/select/code">Multi-selects</Link>{' '}
                and lookup multi-selects now include <code>data-qa-id</code> on
                both selected option labels and remove buttons.
              </Improved>
              <Improved>
                <Link href="/components/forms/select/code">Selects</Link> and{' '}
                <Link href="/components/forms/search-input/code">
                  Search Inputs
                </Link>{' '}
                now truncate overflow text with an ellipses.
              </Improved>
              <Fixed>
                Text alignment in{' '}
                <Link href="/components/buttons/button/design">
                  block status buttons
                </Link>
                .
              </Fixed>
              <Fixed>
                Form elements, including buttons, have explicit font-family to
                avoid accidental overrides.
              </Fixed>
              <Fixed>
                <Link href="/components/select-mark/code">Select mark</Link>{' '}
                inconsistently selecting.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="React Native" />

            <div className={styles.changesList}>
              <Added>Those same icons from the web components package.</Added>
              <Added>
                <code>withSpace()</code> HOC and <code>UniformSpace</code>{' '}
                object with Uniform space values for margin and padding.{' '}
                <Link href="https://github.com/hudl/jarvis/tree/develop/src/uniform#space">
                  See examples
                </Link>
                .
              </Added>
              <Added>
                <code>hasError</code> prop to{' '}
                <Link href="/components/forms/checkbox/code?reactnative">
                  checkboxes, checkbox groups,
                </Link>{' '}
                <Link href="/components/forms/radio/code?reactnative">
                  radios, and radio groups
                </Link>
                .
              </Added>
              <Improved>
                <Link href="/components/avatars/code">Avatars</Link> can now be
                included in the <code>onPress</code> of a{' '}
                <code>TouchableOpacity</code>.
              </Improved>
              <Fixed>
                Illegible letters in text-only{' '}
                <Link href="/components/avatars/code/">avatars</Link>.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Fixed>
                Inconsistent styling for internal and external links.
              </Fixed>
              <Fixed>
                A few incorrect props in a few <code>codeSnippets</code>.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Added>Interactive components for radios and checkboxes.</Added>
              <Added>Checkboxes, checkbox groups, radios and radio group components.</Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
